import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Buttons/BaseButton";
import Table from "../../../components/Table";
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DEAL_COLUMN } from "../../../utils/constant";
import { DEAL_FIELD } from "../../../utils/constant";
import { ACTION, UNIT_USER } from "../../../utils/constant";
import Toast from "../../../components/ToastMessage";
import moment from "moment";
import { fetchUnits } from "../../../store/unitSlice";
import {
  createDeal,
  fetchDeals,
  getDealById,
  removeSearch,
  selectSearch,
  getStage,
  updateDeal,
  deleteDeal,
  setPageSize,
  updateUnitForDeal,
  removeSearchUnits,
  UpdateFilter,
  getFilterById,
  RemoveFilter,
  clearOptionSearch,
  fetchFilterList,
  CreateNewFilter
} from "../../../store/dealsSlice";
import ModalDeal from "../../../components/ModalDeal";
import { fetchCurrencies } from "../../../store/currencySlice";
import { fetchStageDeals } from "../../../store/StageDealSlice";
import { fetchUnitUser } from "../../../store/userSlice";
import { Divider, Paper, Typography, debounce } from "@mui/material";
import { getAllUsers } from "../../../store/teamSlice";
import ModalDelete from '../../../components/ModalCenter/index';
import Paginate from "../../../components/Paginate";
import NotificationModal from "../../../components/NotificationModal";
import HTMLReactParser from "html-react-parser";
import { Helmet } from "react-helmet";
import BasicDateRangePicker from "../../../components/BasicDateRangePicker";
import DropdownFilter from "../../../components/DropdownFilter";
import ModalCenter from "../../../components/ModalCenter";

function Deal() {
  const { t } = useTranslation();
  const firstPage = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [selectedTab, setSelectedTab] = useState("all_deal");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [columns, setColumns] = useState(DEAL_COLUMN);
  const deals = useSelector((state) => state.deals.deals);
  const total_revenue = useSelector((state) => state.deals.total_revenue);
  const [loading, setLoading] = useState(true);
  const page = useSelector((state) => state.deals.page);
  const searchOption = useSelector((state) => state.deals.search_option);
  const total = useSelector((state) => state.deals.total);
  const from = useSelector((state) => state.deals.from);
  const to = useSelector((state) => state.deals.to);
  const pageSize = useSelector((state) => state.deals.limit);
  const [limit, setLimit] = useState(pageSize);
  const error = useSelector((state) => state.deals.error);
  const [currentPage, setCurrentPage] = useState(page);
  const [showAction, setShowAction] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState([]);
  const [searchDone, setSearchDone] = useState(false);
  const inputRef = useRef(null);
  const unitsOriginal = useSelector((state) => state.units.data);
  const [unitsFoSelect, setUnitsForSelect] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const stageDeals = useSelector((state) => state.deals.stages);
  const actions = ACTION.filter((action) => action !== "move_blacklist" && action !== "export");
  const [dealFieldCreate, setDealFieldCreate] = useState(DEAL_FIELD);
  const [pics, setPics] = useState([]);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [messageDeleteDealFail, setMessageDeleteDealFail] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [dataRevenue, setDataRevenue] = useState([]);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [isHasUnit, setIsHasUnit] = useState(false);
  const isRemoveUnit = useSelector((state) => state.deals.isRemoveUnit);
  const filterDeal = useSelector((state) => state.deals.filterList);
  const nameFilter = useSelector((state) => state.deals.nameFilter);
  const [isOpenCreateFilterModal, setIsOpenCreateFilterModal] = useState(false);
  const idFilter = useSelector((state) => state.deals.idFilter);
  useEffect(() => {
    sessionStorage.setItem("deal_error", error);
  }, [error]);

  useEffect(() => {
    updateUnitUser();

  }, []);
  const updateUnitUser = async () => {
    try {
      if (searchOption?.units.length > 0 || isRemoveUnit) {
        dispatch(selectSearch(searchText));
      } else {
        const unitUser = await dispatch(fetchUnitUser());
        if (unitUser in UNIT_USER) {
          await dispatch(updateUnitForDeal({ id: UNIT_USER[unitUser], name: unitUser }));
        } else {
          dispatch(selectSearch(searchText));
        }
      }
      dispatch(fetchDeals(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setIsHasUnit(true);
    } catch (error) { }
  }

  useEffect(() => {
    dispatch(fetchUnits());
    dispatch(getStage());
    dispatch(fetchCurrencies());
    dispatch(fetchStageDeals());
  }, []);

  useEffect(() => {
    setUnitsForSelect(unitsOriginal.map(({ id, name }) => ({ id, name })));
  }, [unitsOriginal]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setShowAction(false);
    setSelectedDeal([]);
    setCheckedList([]);
    dispatch(fetchDeals(page, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleSearch(tab);
    localStorage.setItem('currentTabDeal', tab);
    setShowAction(false);
  };

  const handleRowClick = (deal) => {
    setSelectedDeal(deal);
    navigate(`/deal/${deal.id}`, { state: { deal } });
  };

  const onCheckboxClick = (event, option, type) => {
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    setSearchDone(true);
    setLoading(true);
    setShowAction(false);
    dispatch(selectSearch(option, type));
    dispatch(fetchDeals(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = (checkbox) => {
    setCheckedList(checkbox);
    if (checkbox.length === 1) {
      dispatch(getDealById(checkbox[0]))
        .then((deal) => {
          setSelectedDeal(deal);
        })
        .catch((error) => {
          setSelectedDeal([]);
        });
    }
    setSelectedDeal([]);
    setShowAction(checkbox.length > 0);
  };
  const getDealForEdit = () => {
    if (selectedDeal) {
      let contacts = [];
      selectedDeal.contacts.forEach((item) => {
        contacts.push({ label: item.name, value: item.id });
      });
      let contactPoints = [];
      selectedDeal.contact_points.forEach((item) => {
        contactPoints.push({ label: item.full_name, value: item.id, contacts: item.contacts.map((x) => x.id) });
      });
      const deal = {
        id: selectedDeal.id,
        name: selectedDeal.name,
        "contact": contacts,
        contact_point: contactPoints,
        owner: selectedDeal.user !== null ? { label: `${selectedDeal.user.name} (${selectedDeal.user.email})`, value: selectedDeal.user.id } : null,
        team: selectedDeal.team !== null ? { label: selectedDeal.team.name, value: selectedDeal.team.id } : null,
        stage: selectedDeal.stage !== null ? { label: selectedDeal.stage.name, value: selectedDeal.stage.id } : null,
        "expected_close_date": selectedDeal.closing_date,
        "expected_revenue": selectedDeal.revenue,
        "currency": selectedDeal.currency !== null ? { label: selectedDeal.currency.name, value: selectedDeal.currency.id } : null
      }
      return deal;
    }
    return null;
  }

  const onEdit = () => {
    setIsOpenEditModal(true);
  };

  const handleSearch = (tab) => {
    setLoading(true);
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchDeals(1, tab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeTag = (category) => {
    if (category === "text") {
      setSearchText("");
    }
    setSearchDone(true);
    setLoading(true);
    if (category === "units") {
      dispatch(removeSearchUnits(category));
    } else {
      dispatch(removeSearch(category));
    }
    setCurrentPage(firstPage);
    navigate(`?page=${firstPage}`);
    dispatch(fetchDeals(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleModalClose = () => {
    setIsOpenCreateModal(false);
    setIsOpenEditModal(false);
    setIsOpenCreateFilterModal(false);
  };
  const handleCreate = async (deal) => {
    setLoading(true);
    let createSuccess = await dispatch(createDeal(deal));
    if (createSuccess) {
      setToast({ show: true, message: t("Deal created successfully") });
      handleModalClose();
    }
    setLoading(false);
  };

  const handleUpdate = async (deal) => {
    setLoading(true);
    let updateSuccess = await dispatch(updateDeal(deal));
    if (updateSuccess) {
      setToast({ show: true, message: t("Deal updated successfully") });
      handleModalClose();
      setShowAction(false);
    }
    setLoading(false);
  }

  const handleDeleteDeal = async () => {
    setLoading(true);
    let deleteResponse = await dispatch(deleteDeal(userInfo.user.id, checkedList));
    if (deleteResponse.status_code === 200) {
      setToast({ show: true, message: t("Deal deleted successfully") });
      setCheckedList([]);
    } else {
      setMessageDeleteDealFail(deleteResponse.error);
      setShowAction(false);
      setLoading(false);
    }
    setIsOpenModalDelete(false);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    if (searchOption?.text.length != 0) {
      setSearchText(...searchOption?.text);
    }
    setSearchDone(true);
    setIsFirstLoad(true);
    setShowAction(false);
    dispatch(fetchFilterList("deal"))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
    if (isHasUnit) {
      dispatch(fetchDeals(page, selectedTab))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    setToast({ show: false, message: "" });
  }, []);

  useEffect(() => {
    if (searchDone) {
      setLoading(true);
      const unitMatched = unitsFoSelect.find(
        (unit) => unit.name === searchText
      );
      if (unitMatched) {
        dispatch(selectSearch(unitMatched, "units"));
      } else {
        dispatch(selectSearch(searchText));
      }
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        dispatch(fetchDeals(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  }, [searchText, searchDone]);

  useEffect(() => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setData(deals);
  }, [deals]);

  useEffect(() => {
    setDataRevenue(total_revenue);
  }, [total_revenue])

  const debounceDropDown = useCallback(debounce((searchText) => getUsers(searchText), 200), []);

  const getUsers = async (searchText) => {
    try {
      if (searchText === "") {
        setPics([]);
        return;
      }
      const result = await dispatch(getAllUsers(searchText));
      let listUser = result.payload.data;
      setPics(listUser);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchPics = (searchText) => {
    debounceDropDown(searchText);
  };

  const applyCallbackCreateDate = (startDate, endDate) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setSearchDone(true);
    setShowAction(false);
    setLoading(true);
    dispatch(selectSearch(option, "createDate"));
    dispatch(fetchDeals(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const rangeContact = {
    "Last Week Only": [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    "Last Month Only": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "This Month Only": [moment().startOf("month"), moment().endOf("month")],
  };

  const handleOpenFilterModal = (status) => {
    setIsOpenCreateFilterModal(status);
  };
  const handleUpdateFilter = async (filter) => {
    setLoading(true);
    try {
      await dispatch(UpdateFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: t('message.filter_update_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_update_filter') });
    }
  };
  const handleGetFilter = async (id) => {
    setSearchDone(true);
    setLoading(true);
    const allUsers = await dispatch(getAllUsers());
    await dispatch(getFilterById(id,stageDeals,allUsers));
    dispatch(fetchDeals(1, selectedTab))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setShowAction(false);
  };
  const handleRemoveFilter = async (id) => {
    let dataSearch = {};
    setLoading(true);
    try {
      if (id === idFilter) {
        for (let key in searchOption) {
          dataSearch[key] = [];
        }
        await dispatch(RemoveFilter(id));
        dispatch(clearOptionSearch(dataSearch));
        dispatch(fetchDeals(1, selectedTab))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
        handleReloadFilterList();
      } else {
        await dispatch(RemoveFilter(id));
        handleReloadFilterList();
      }
      setToast({
        show: true,
        message: t('message.filter_remove_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_remove_filter') });
    }
  };
  const handleReloadFilterList = async () => {
    setLoading(true);
    await dispatch(fetchFilterList("deal"))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  const handleCreateFilter = async (filter) => {
    setLoading(true);
    try {
      filter.type = "deal";
      await dispatch(CreateNewFilter(filter))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setToast({
        show: true,
        message: t('message.filter_create_success'),
      });
    } catch (error) {
      setLoading(false);
      setToast({ show: true, message: t('message.error_create_filter') });
    }
  };
  return (
    <>
      <Helmet>
        <title>{t("deal")}</title>
        <meta name="description" content="Description of Deals Page" />
      </Helmet>
      <div className="deal-screen tab-container">
        {loading && <Loading />}
        <div className="d-flex" style={{ position: "relative" }}>
          <p className="bread-crumb">{t("deal")}</p>
          <div className="search-field search-input">
            <div style={{ paddingRight: "35px" }}>
              <input
                className={searchDone ? '' : 'input-black'}
                type="text"
                ref={inputRef}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setSearchDone(false);
                }}
                onBlur={(e) => {
                  setSearchDone(true);
                }}
                onFocus={(e) => {
                  setSearchDone(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    setSearchDone(true);
                  }
                }}
              />
              <div className="fields-search">
                {searchDone &&
                  Object.entries(searchOption).map(([category, options]) => {
                    if (options.length === 0) {
                      return null;
                    }
                    const nameTag = options.map((item) =>
                      typeof item === "object"
                        ? item.name ||
                        item.startDate.format("YYYY/MM/DD") +
                        "->" +
                        item.endDate.format("YYYY/MM/DD")
                        : item
                    );
                    const categoryLabel =
                      category?.charAt(0).toUpperCase() + category.slice(1);
                    const tag = `${categoryLabel}: ${nameTag.join(" or ")}`;
                    const isTextCategory = category === "text";
                    const spanClass =
                      isTextCategory &&
                        (searchOption["stages"].length > 0 ||
                          searchOption["units"].length > 0 ||
                          searchOption["pics"].length > 0)
                        ? "tag tag-text"
                        : `tag tag-${category.toLowerCase()}`;

                    return (
                      <span
                        key={category}
                        className={spanClass}
                        onClick={(e) => {
                          if (
                            category === "text" &&
                            e.target.tagName !== "BUTTON"
                          ) {
                            setSearchDone(false);
                            inputRef.current.focus();
                          }
                        }}
                      >
                        {tag}
                        <button
                          onClick={() => removeTag(category)}
                          type="button"
                          className="tag-remove-button"
                        >
                          &times;
                        </button>
                      </span>
                    );
                  })}
              </div>
            </div>
            <SearchIcon
              style={{
                color: "#5F89BA",
                fontSize: "1.3rem",
              }}
            />
          </div>
        </div>
        <div className="flex" style={{ alignItems: "center", marginTop: "20px" }}>
          <Button
            backgroundColor="#5F89BA"
            color="#FFFFFF"
            text={t("create")}
            style={{ marginRight: "10px", marginTop: "0px" }}
            onClick={() => setIsOpenCreateModal(true)}
          />
          {dataRevenue && dataRevenue.length > 0 && <Paper variant="outlined" style={{ height: "50px", textAlign: "center", alignItems: "center", display: "flex" }}>
            {dataRevenue?.map((item, index) => (
              <>
                <div style={{ paddingInline: "10px", display: "grid" }}>
                  <div style={{ fontSize: "14px" }}>{t("total_expected_revenue")}</div>
                  <div className="bold" style={{ width: "180px", display: "flex", justifyContent: "center" }}>
                    <div className="total-revenue" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "2px" }}>{Number(item.total).toLocaleString()}</div> {item.currency ?? ""}</div>
                </div>
                {index < dataRevenue.length - 1 &&
                  <Divider sx={{ bgcolor: "rgb(95, 137, 186)" }} orientation="vertical" variant="middle" flexItem />}
              </>
            ))}
          </Paper>}

        </div>
        <div className="header-items">
          <div className="tabs">
            <div
              className={`tab ${selectedTab === "all_deal" ? "selected" : ""}`}
              onClick={() => handleTabChange("all_deal")}
            >
              {t('all_deal')}
            </div>
            <div
              className={`tab tab-mydeal ${selectedTab === "my_deal" ? "selected" : ""
                }`}
              onClick={() => handleTabChange("my_deal")}
            >
              {t('my_deal')}

            </div>
          </div>
          <div className="filter-container">
            <div className="filter">
              {showAction && selectedDeal && (
                <div className={`tab d-flex action`} style={{ color: "black" }}>
                  <Dropdown
                    options={actions}
                    component={"action"}
                    searchOption={searchOption}
                    checkboxValue={selectedDeal}
                    onEdit={onEdit}
                    onDelete={() => setIsOpenModalDelete(true)}
                    styles={{
                      whiteSpace: "nowrap",
                    }}
                  ></Dropdown>
                </div>
              )}
              <div
                className={`tab ${selectedTab === "all_deal" ? "selected" : ""
                  } d-flex dropdown-stages`}
              >
                <Dropdown
                  options={stageDeals}
                  component={t("stage")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"stages"}
                ></Dropdown>
              </div>
              <div
                className={`tab ${selectedTab === "all_deal" ? "selected" : ""
                  } d-flex dropdown-units`}
              >
                <Dropdown
                  options={unitsFoSelect}
                  component={t("unit")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"units"}
                ></Dropdown>
              </div>
              <div
                className={`tab ${selectedTab === "all_deal" ? "selected" : ""
                  } d-flex dropdown-units`}
              >
                <Dropdown
                  options={pics}
                  component={"PIC"}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  onCheckboxClick={onCheckboxClick}
                  typeSelect={"pics"}
                  onSearch={handleSearchPics}
                ></Dropdown>
              </div>
              <div
                className={`tab ${selectedTab === "all_deal" ? "selected" : ""
                  } d-flex dropdown-create-date`}
              >
                <BasicDateRangePicker
                  start={startCreate}
                  end={endCreate}
                  applyCallback={applyCallbackCreateDate}
                  contact={true}
                  component={t("created_date")}
                  rangesContact={rangeContact}
                  type={"createDate"}
                />
              </div>

              <div
                className={"tab selected"}
              >
                <DropdownFilter
                  options={filterDeal}
                  component={nameFilter ? nameFilter : t("my_filter")}
                  searchOption={searchOption}
                  withCheckBox={true}
                  withSearch={true}
                  isOpenFilter={isOpenCreateModal}
                  setIsOpenFilter={handleOpenFilterModal}
                  handleUpdateFilter={handleUpdateFilter}
                  getFilter={handleGetFilter}
                  removeFilter={handleRemoveFilter}
                  idFilter={idFilter}
                  onClose={handleModalClose}
                  onUpdate={handleUpdateFilter}
                  total={total}
                  styleMenu={{
                    left: "auto",
                    right: "0px",
                  }}
                ></DropdownFilter>
              </div>
            </div>
          </div>
        </div>
        {!loading && (
          <Table
            data={data}
            columns={columns}
            searchTerm={selectedTab}
            handleRowClick={handleRowClick}
            handleCheckBox={handleCheckBox}
          // updateContact={updateDealStage}
          />
        )}
        {total !== 0 && !loading ? (
          <Paginate
            totalRecords={total}
            from={from}
            to={to}
            limit={limit}
            pageSize={pageSize}
            currentPage={currentPage}
            onChangeLimit={(limit) => {
              setLimit(limit)
            }}
            onChangePage={(page) => {
              handlePageClick(page);
            }}
            onSetPageSize={() => {
              let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
              dispatch(setPageSize(limit));
              handlePageClick(newPage);
            }}
          />
        ) : null}
        {toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )}
        {isOpenCreateModal && (
          <ModalDeal
            title={t("create_deal")}
            isOpen={isOpenCreateModal}
            onClose={handleModalClose}
            onSubmit={handleCreate}
            fields={dealFieldCreate}
            setField={setDealFieldCreate}
          />
        )}
        {isOpenEditModal && (
          <ModalDeal
            title={t("edit_deal")}
            isOpen={isOpenEditModal}
            isCreate={false}
            isEdit={true}
            onClose={handleModalClose}
            fields={dealFieldCreate}
            setField={setDealFieldCreate}
            deal={getDealForEdit()}
            onSubmit={handleUpdate}
          />
        )}
        {isOpenModalDelete && (
          <ModalDelete
            isOpen={isOpenModalDelete}
            title={t('confirm_delete')}
            onClose={() => {
              setIsOpenModalDelete(false);
            }}
            name={checkedList.length === 1 ? t("this_deal") : t("these_deal")}
            useConfirm={true}
            type={'filter'}
            onConfirm={() => handleDeleteDeal()}
          />
        )}
        {messageDeleteDealFail && (
          <NotificationModal
            title={t('notification')}
            content={HTMLReactParser(messageDeleteDealFail)}
            isOpen={messageDeleteDealFail != null}
            onClose={() => setMessageDeleteDealFail(null)}
          />
        )}
        <ModalCenter
          title="Create Filter"
          isOpen={isOpenCreateFilterModal}
          searchOption={searchOption}
          onClose={handleModalClose}
          onCreate={handleCreateFilter}
          fields={[{ label: "Name", type: "text", required: true }]}
          onFilter={true}
          onReload={handleReloadFilterList}
          onLoadingBegin={() => {
            setLoading(true);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
        />
      </div>
    </>
  );
}

export default Deal;
