import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import { getTypeForOption, makeURLSearchParams } from '../utils/helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../services/client';
import moment from "moment";

const initialState = {
    deals: [],
    stages: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    total_revenue: [],
    search_option: {
        stages: [],
        units: [],
        teams: [],
        pics: [],
        createDate: [],
        lastUpdated: [],
        text: [],
    },
    error: null,
    idFilter: 0,
    filters: [],
    filterList: [],
    nameFilter: "",
};

const dealsSlice = createSlice({
    name: 'deals',
    initialState,
    reducers: {
        getDealsStart: (state) => {
            state.loading = true;
        },
        getDealsSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.current_page;
            state.total = action.payload.total;
            state.deals = action.payload.data;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.last_page = action.payload.last_page;
            state.total_revenue = action.payload.total_renevue;
        },
        getDealsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateOptionSearch: (state, action) => {
            const data = action.payload;
            const type = data.type ? data.type : getTypeForOption(data.option);
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': data.option ? [data.option] : [],
                };
                return;
            }

            if (type === "teams") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].map(item => item.id).includes(data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "stages") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "pics") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "units") {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].find(item => item.id === data.option.id) ?
                        state.search_option[type].filter((value) => value.id !== data.option.id) :
                        [...state.search_option[type], data.option],
                };
                return;
            }

            if (type === "createDate" || type === "lastUpdated") {
                state.search_option = {
                    ...state.search_option,
                    [type]: [data.option],
                };
            } else {
                state.search_option = {
                    ...state.search_option,
                    [type]: state.search_option[type].includes(data.option) ?
                        state.search_option[type].filter((value) => value !== data.option) :
                        [...state.search_option[type], data.option],
                };
            }
        },
        clearOptionSearch: (state, action) => {
            state.search_option = action.payload;
            state.idFilter = 0;
            state.nameFilter = "";
        },
        deleteOptionSearch: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
        },
        deleteOptionSearchUnits: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
            state.isRemoveUnit = true;
        },
        getStages: (state, action) => {
            state.stages = action.payload.data
        },
        createDealStart: (state) => {
            state.loading = true;
        },
        createDealSuccess: (state, action) => {
            state.loading = false;
            state.deals.unshift(action.payload.data);
        },
        createDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateDealStart: (state) => {
            state.loading = true;
        },
        updateDealSuccess: (state, action) => {
            state.loading = false;
            let item = action.payload.data;
            let foundIndex = state.deals.findIndex(x => x.id === item.id);
            state.deals[foundIndex] = item;
        },
        updateDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteDealStart: (state) => {
            state.loading = false;
        },
        deleteDealSuccess: (state, action) => {
            state.loading = false;
            if (typeof (action.payload) === "object") {
                state.deals = state.deals.filter(
                    (item) => !action.payload.includes(item.id)
                );
            } else {
                state.deals = state.deals.filter(
                    (item) => item.id !== action.payload
                );
            }

        },
        deleteDealFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        updateOptionSearchUnit: (state, action) => {
            state.search_option = {
                ...state.search_option,
                units: [action.payload.option]
            };
        },
        createFilterStart: (state) => {
            state.loading = true;
        },
        getFilterStart: (state) => {
            state.loading = true;
        },
        getFilterListSuccess: (state, action) => {
            state.loading = false;
            state.filterList = action.payload.data;
        },
        getFilterListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getFilterSuccess: (state, action) => {
            state.loading = false;
            state.search_option = action.payload.dataFilter;
            state.idFilter = action.payload.id;
            state.nameFilter = action.payload.name;
        },
        getFilterFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createFilterSucess: (state, action) => {
            state.loading = false;
            state.filters = action.payload.data;
        },
        createFilterFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateFilterStart: (state) => {
            state.loading = true;
        },
        updateFilterSucess: (state, action) => {
            state.loading = false;
            state.filters = action.payload;
        },
        updateFilterFailure: (state, action) => {
            state.loading = false;
            state.filters = action.payload;
        },
        deleteFilterSuccess: (state, action) => {
            state.loading = false;
        },
        deleteFilterFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getDealsStart,
    getDealsSuccess,
    getDealsFailure,
    updateOptionSearch,
    deleteOptionSearch,
    clearOptionSearch,
    getStages,
    createDealStart,
    createDealSuccess,
    createDealFailure,
    updateDealStart,
    updateDealSuccess,
    updateDealFailure,
    deleteDealStart,
    deleteDealSuccess,
    deleteDealFailure,
    setLimit,
    updateOptionSearchUnit,
    deleteOptionSearchUnits,
    getFilterStart,
    getFilterListSuccess,
    getFilterListFailure,
    getFilterSuccess,
    getFilterFailure,
    createFilterStart,
    createFilterSucess,
    createFilterFailure,
    updateFilterStart,
    updateFilterSucess,
    updateFilterFailure,
    deleteFilterSuccess,
    deleteFilterFailure,
} = dealsSlice.actions;

export const fetchDeals = (page = 1, tab) => async (dispatch, getState) => {
    const searchOption = getState().deals.search_option;
    const limit = getState().deals.limit;

    try {
        dispatch(getDealsStart());
        const params = {
            page: page,
            flag: tab,
            limit: limit,
        };

        if (searchOption) {
            const { stages, pics, units, createDate, lastUpdated, text } = searchOption;

            if (stages?.length > 0) {
                params.stage = stages.map(stage => stage.id).join(',').toLowerCase();
            }

            if (pics?.length > 0) {
                params.user = pics.map(stage => stage.id).join(',').toLowerCase();
            }

            if (units?.length > 0) {
                params.unit = units.map(unit => unit.id).join(',').toLowerCase();
            }
            if (createDate?.length > 0) {
                params.create_date_start = createDate[0].startDate;
                params.create_date_end = createDate[0].endDate;
            }
            if (lastUpdated?.length > 0) {
                params.update_date_start = lastUpdated[0].startDate;
                params.update_date_end = lastUpdated[0].endDate;
            }
            if (text?.length > 0) {
                params.search = text.join(',').toLowerCase();
            }
        }

        const query = makeURLSearchParams(params);
        const url = `${API.listDeals}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getDealsSuccess(responseJson));
    } catch (error) {
        dispatch(getDealsFailure(error.message));
    }
};

export const createDeal = (deal) => async (dispatch) => {
    try {
        dispatch(createDealStart());
        const response = await request(`${API.createDeal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createDealSuccess(responseJson));
            return true;
        } else {
            dispatch(createDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createDealFailure(error.message));
        return false;
    }
};
export const fetchFilterList = (type) => async (dispatch) => {
    try {
        dispatch(getFilterStart());
        let url = `${API.listFilter}${makeURLSearchParams({ type })}`;

        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getFilterListSuccess(responseJson));
    } catch (error) {
        dispatch(getFilterListFailure(error.message));
    }
};

export const getFilterById = (id, stageDeals, allUsers) => async (dispatch, getState) => {
    let dataFilter = {};
    try {
        const units = getState().units.data;
        dispatch(getFilterStart());
        let url = `${API.showFilter}${makeURLSearchParams({ id })}`;
        const response = await request(url);
        const responseJson = await response.json();

        for (let key in responseJson.data.data) {
            if (responseJson.data.data[key].length > 0) {
                dataFilter[key] = responseJson.data.data[key].map((value) => {
                    switch (key) {
                        case "text":
                        case "lists":
                            return value;
                        case "pics":
                            return allUsers.payload.data.find((user) => user.id == value) ?? null;
                        case "stages":
                            return stageDeals.find((stage) => stage.id == value) ?? null;
                        case "units":
                            return units.find((unit) => unit.id == value) ?? null;
                        case "createDate":
                            return {
                                startDate: moment(value.startDate),
                                endDate: moment(value.endDate),
                            };
                        case "lastUpdated":
                            return {
                                startDate: moment(value.startDate),
                                endDate: moment(value.endDate),
                            };
                        default:
                            return value.length > 3
                                ? value.charAt(0).toUpperCase() +
                                value.slice(1).replace("_", " ")
                                : value.toUpperCase();
                    }
                });
            } else {
                dataFilter[key] = [];
            }
        }
        const data = {
            id: id,
            name: responseJson.data.name,
            dataFilter: dataFilter,
        };

        dispatch(getFilterSuccess(data));
    } catch (error) {
        dispatch(getFilterFailure(error.message));
    }
};

export const CreateNewFilter = (filter) => async (dispatch) => {
    let dataFilter = {};
    for (let key in filter.filterValue) {
        dataFilter[key] = filter.filterValue[key].map((value) =>
            typeof value === "string"
                ? value.replace(/\s+/g, "_").toLowerCase()
                : value?.id || {
                    startDate: value.startDate.toDate(),
                    endDate: value.endDate.toDate(),
                }
        );
    }
    let body = {
        type: filter?.type,
        name: filter?.inputValues?.Name,
        data: dataFilter,
    };
    try {
        dispatch(createFilterStart(body));
        const response = await request(`${API.storeFilter}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        const createdFilter = await response.json();
        dispatch(createFilterSucess(createdFilter));
    } catch (error) {
        dispatch(createFilterFailure(error.message));
    }
};

export const UpdateFilter = (filter) => async (dispatch) => {
    let dataFilter = {};
    for (let key in filter.filterValue) {
        dataFilter[key] = filter.filterValue[key].map((value) =>
            typeof value === "string"
                ? value.replace(/\s+/g, "_").toLowerCase()
                : value?.id || { startDate: value.startDate, endDate: value.endDate }
        );
    }
    let body = {
        id: filter?.idFilter,
        data: dataFilter,
    };
    try {
        dispatch(updateFilterStart());
        const response = await request(`${API.updateFilter}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        const updateFilter = await response.json();
        dispatch(updateFilterSucess(updateFilter));
    } catch (error) {
        dispatch(updateFilterFailure(error.message));
    }
};

export const RemoveFilter = (id) => async (dispatch) => {
    try {
        dispatch(getFilterStart());
        let url = `${API.deleteFilter}?id=${id}`;
        const response = await request(url, {
            method: "DELETE",
        });
        const responseJson = await response.json();

        dispatch(deleteFilterSuccess(responseJson));
    } catch (error) {
        dispatch(deleteFilterFailure(error.message));
    }
};

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }

    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const updateUnitForDeal = (option) => async (dispatch) => {
    const data = {
        option,
    }
    try {
        dispatch(updateOptionSearchUnit(data));
    } catch (error) {
        //
    }
};

export const clearSearch = (data) => async (dispatch) => {
    try {
        dispatch(clearOptionSearch(data));
    } catch (error) {
        //
    }
};

export const removeSearch = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearch(category));
    } catch (error) {
        //
    }
};

export const removeSearchUnits = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearchUnits(category));
    } catch (error) {
        //
    }
};

export const getDealById = (id) => async (dispatch, getState) => {
    try {
        let url = `${API.getDealById}?id=${id}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            const deal = responseJson.data;
            return deal;
        }
        return null;
    } catch (error) {
        //
    }
};

export const getStage = () => async (dispatch) => {
    try {
        let url = `${API.stageDeals}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getStages(responseJson));
    } catch (error) {
        //
    }
}

export const updateDeal = (deal) => async (dispatch) => {
    try {
        dispatch(updateDealStart());
        let url = `${API.updateDeal}?`;
        const response = await request(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(updateDealSuccess(responseJson));
            return true;
        } else {
            dispatch(updateDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(updateDealFailure(error.message));
        return false;
    }
}

export const getDealsByContact = (contactId, query) => async () => {
    try {
        const url = `${API.getDealsByContact}${makeURLSearchParams({ contact_id: contactId, ...query })}`;
        const response = await request(url);

        if (response.ok) {
            return await response.json();
        }

        throw Error('Fetch data failed.');
    } catch (error) {
        throw error;
    }
}

export const getAllDealsByContact = (contactId) => async () => {
    try {
        const url = `${API.getAllDealsByContact}${makeURLSearchParams({ contact_id: contactId })}`;
        const response = await request(url);

        if (response.ok) {
            return await response.json();
        }

        throw Error('Fetch data failed.');
    } catch (error) {
        throw error;
    }
}

export const deleteDeal = (userId, ids) => async (dispatch) => {
    try {
        dispatch(deleteDealStart());
        const response = await request(`${API.deleteDeal}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ deal_ids: ids, id: userId }),
        });
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(deleteDealSuccess(ids));
            return responseJson;
        } else {
            dispatch(deleteDealFailure(responseJson.error));
            return responseJson;
        }
    } catch (error) {
        dispatch(deleteDealFailure(error.message));
        return false;
    }
};

export const createDealByTask = (deal) => async (dispatch) => {
    try {
        dispatch(createDealStart());
        const response = await request(`${API.storeDealByTask}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...deal }),
        });

        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
            dispatch(createDealSuccess(responseJson));
            return true;
        } else {
            dispatch(createDealFailure(responseJson.error));
            return false;
        }
    } catch (error) {
        dispatch(createDealFailure(error.message));
        return false;
    }
};

export const setPageSize = (data) => async (dispatch) => {
    try {
        dispatch(setLimit(data));
    } catch (error) {
        //
    }
};

export default dealsSlice;